import React from 'react';

function WishlistRow({ wishlist, onItemSelect }) {
    return (
        <div className="wishlist-card" onClick={() => onItemSelect(wishlist)}>
            <h4 className="wishlist-name">{truncateText(wishlist.name, 50)} <span className="wishlist-owner">({truncateText(wishlist.owner_name, 30)})</span></h4>
            <p className="wishlist-description">{truncateText(wishlist.description, 100)}</p>
        </div>
    );
}

// Helper function to truncate text
function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

export default WishlistRow;
