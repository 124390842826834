import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWindowClose, faTimes, faCheckCircle, faHeart, faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';

import { useUser } from './UserContext';
import { API_URL } from './constants';
import './WishDetailsModal.css';

function WishDetailsModal({ item, onClose, onDelete, isOwner }) {

    const { userId } = useUser();

    const postedDate = new Date(item.date_posted).toLocaleDateString("en-US", {
        year: 'numeric', month: 'long', day: 'numeric'
    });

    const handleDeleteItem = async (wishId) => {
        try {
            const response = await fetch(`${API_URL}/delete-wish/${wishId}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            if (response.ok) {
                onDelete(wishId);  // Call the onDelete function with the item ID
                alert('Wish entry deleted successfully');
                onClose();
            } else {
                const errorData = await response.json();
                alert(errorData.message);
            }
        } catch (error) {
            console.error('Failed to delete the wish entry:', error);
            alert('Error deleting the wish entry');
        }
    };

    const handleBackgroundClick = (event) => {
        if (event.target === event.currentTarget) onClose();
    };

    return (
        <div className="modal" onClick={handleBackgroundClick}>
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close-btn" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    {isOwner && (
                        <button className="btn-danger" onClick={() => handleDeleteItem(item.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                </div>



                {/* 5. Item details */}
                <div className="item-info">
                    <p className="item-name">Wish {item.id}: {item.name}</p>
                    <p className="item-description">{item.description}</p>
                    <p className="item-date">Posted on: {postedDate}</p>
                    <p className="item-owner">Requester: {item.owner_name}</p>

                </div>

                {isOwner && (
                    <div className="item-details">
                        {/* 4. Privacy Status */}
                        <div className="privacy-status">
                            <p >
                                {item.privacy_setting === 'public' ? 'Visible to my network' : 'Private to me'}
                            </p>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );

}

export default WishDetailsModal;
