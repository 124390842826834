import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(() => {
        const storedId = localStorage.getItem('userId');
        console.log("UserProvider initialization: Retrieved userId from localStorage:", storedId);

        return storedId ? parseInt(storedId, 10) : undefined;
    });

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const updateUserId = () => {
            const newUserId = localStorage.getItem('userId');
            console.log("Updating userId from localStorage:", newUserId);

            setUserId(newUserId ? parseInt(newUserId, 10) : null);
            setIsLoading(false);

        };

        updateUserId(); // Call immediately to set initial state

        // Listen for login and logout events
        window.addEventListener('userLoggedIn', updateUserId);
        window.addEventListener('userLoggedOut', updateUserId);

        console.log("Initial userId:", userId);

        return () => {
            window.removeEventListener('userLoggedIn', updateUserId);
            window.removeEventListener('userLoggedOut', updateUserId);
        };
    }, []);

    return (
        <UserContext.Provider value={{ userId , isLoading}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
