import React from 'react';
import { useLocation } from 'react-router-dom';

function Home() {
  const location = useLocation();
  const { username } = location.state || { username: "User" };

  return (
    <h1>Hello, {username}</h1>
  );
}

export default Home;

