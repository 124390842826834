import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>CommunityCycle Story</h1>
      <p>
        CommunityCycle emerged from a commitment to strengthen tight-knit communities like WSP (Waldorf School of Peninsula), where trust and shared values pave the way for sustainable living. More than just a platform, CommunityCycle empowers parents, neighbors to share everyday items, from toys and clothes to tools and sports equipment, functioning as a communal library that extends far beyond books.
      </p>
      <h2>How CommunityCycle Works:</h2>
      <ul>
        <li>
          <strong>Borrow & Share:</strong> CommunityCycle thrives on the principle of sharing what we have and borrowing what we need. Imagine borrowing a camping tent for your next trip or finding a new home for your gently used kitchen mixer that's gathering dust. Our community's generosity drives our platform, making it possible to find items for temporary or long-term use.
        </li>
        <li>
          <strong>Wish List:</strong> Can't find what you’re looking for? Your wishes drive our community forward. Post your needs to inspire others to share items they hadn't considered before, thus enhancing our shared resource pool.
        </li>
      </ul>
      <h2>Your Impact:</h2>
      <p>
        Each item shared on CommunityCycle means one less item bought new, easing the environmental impact and fostering a culture of reuse. Our platform has seen parents, neighbors, and friends come together, bonded by a shared goal to support and sustain one another. Every exchange on CommunityCycle helps build a more connected and sustainable community.
      </p>
      <h3>Join our journey—share an item, share a story.</h3>
     
    </div>
  );
};

export default About;
