import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import './FAQ.css';

const FAQ = () => {
  const [openSection, setOpenSection] = useState(null);
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleSection = (index) => {
    if (openSection === index) {
      setOpenSection(null);
      setOpenQuestion(null);
    } else {
      setOpenSection(index);
      setOpenQuestion(null);
    }
  };

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqs = [
    {
      title: "Getting Started",
      questions: [
        { question: "How do I subscribe to my community or individual connections?", 
          answer: "Tap on '≡', go to Settings, and subscribe to your Community Group or Connections." },
        { question: "I don't see any items posted by the community, what should I do?", 
          answer: "Ensure you have subscribed to the correct community groups or connections in your settings." }
      ]
    },
    {
      title: "Posting and Managing Items",
      questions: [
        { question: "How do I post an item as a giveaway?", 
          answer: "Tap on 'Giveaways', then tap the 'Give' icon on the bottom right to guide you through the process." },
        { question: "How do I post an item for short-term borrow?", 
          answer: "Tap on 'Borrowables', then tap the 'Lend' icon on the bottom right to guide you through the process." }
      ]
    },
    {
      title: "Requesting and Tracking Items",
      questions: [
        { question: "I can't find an item that I'm interested in, how do I post my request/wish?", 
          answer: "On the Items or Wishlist page, tap the 'Wish' icon on the bottom left to guide you through the process." },
        { question: "I just expressed interest in an item, how do I track its status?", 
          answer: "Tap on the home icon (⌂) on the top bar and then tap on 'My Requests' to check the status of your item request." },
        { question: "I just transferred an item physically to a requester, how do I update the status?", 
          answer: "Tap on the home icon (⌂), then 'My Shared Items' -> 'Pending Transfer', and tap on 'Confirm Transfer' to complete the process." }
      ]
    },
    {
        title: "Wishlist",
        questions: [
          { question: "What are wishlists and what purpose do they serve?", 
            answer: "Wishlists allow you to post items that you are looking to borrow or receive. They serve to communicate your needs to the community, enabling others to offer items that match your wishes." },
          { question: "How do I check the status of my wishlist?", 
            answer: "When one or more matching items are found, either from existing items or those added in the future, these matches will appear under 'Matched Wishes.' You can navigate to this by tapping the Home icon, then selecting 'My Wishlist'." }
        ]
      },
    {
      title: "Support and Feedback",
      questions: [
        { question: "I have some questions or suggestions, who do I reach out to?", 
          answer: "Contact Priyanka Rajan or post your comment on the CommunityCycle App for any questions or suggestions." }
      ]
    }
  ];

  return (
    <div className="faq-page">
      <h1>FAQs</h1>
      {faqs.map((section, sectionIndex) => (
        <div key={sectionIndex} className={`faq-section ${openSection === sectionIndex ? 'active-section' : ''}`}>
          <h2 onClick={() => toggleSection(sectionIndex)}>
            {section.title}
          </h2>
          {openSection === sectionIndex && (
            <div>
              {section.questions.map((item, questionIndex) => (
                <div key={questionIndex} className="faq-question">
                  <h3 onClick={() => toggleQuestion(questionIndex)}>
                    <FontAwesomeIcon icon={openQuestion === questionIndex ? faMinusCircle : faPlusCircle} size="lg" color="#007bff" />
                    <span className="question-text">{item.question}</span>
                  </h3>
                  {openQuestion === questionIndex && (
                    <div className="faq-answer">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
