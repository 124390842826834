import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTasks, faLineChart } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import { useAuth } from './AuthContext';
import { UserProvider } from './UserContext';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import Items from './Items';
import Wishes from './Wishes';
import About from './About';
import FAQ from './FAQ';
import Admin from './Admin';
//import withUser from './withUser';  // Adjust this path to where you saved withUser.js


import { useUser } from './UserContext';

import MyActivity from './MyActivity';
import Settings from './Settings';
import ProtectedRoute from './ProtectedRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const adminUserIds = [1, 2]; // List of admin user IDs

function AdminRoute({ children }) {
  const { userId } = useUser();
  const isAdmin = adminUserIds.includes(userId);
  console.log("Admin Route Check: ", { userId, isAdmin });

  if (!userId) {
    console.log("Redirecting to login because no user ID is present.");
    return <Navigate to="/login" />;
  } else if (!isAdmin) {
    console.log("Redirecting to home because the user is not an admin.");
    return <Navigate to="/home" />;
  }
  return children;
}

function Navigation() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(window.location.pathname); // Set initial path based on the current URL
  const { userId } = useUser();

  const [notifications, setNotifications] = useState({
    approve_interests: 0,
    approved_new_requests: 0,
    new_matching_wishlist: 0
  });

  const totalNotifications = notifications.approve_interests + notifications.approved_new_requests + notifications.new_matching_wishlist;



  // Function to fetch notifications
  const fetchNotifications = async () => {
    try {
      // Adjust to include userId as a query parameter
      const response = await axios.get(`/api/notifications?userId=${userId}`);
      setNotifications({
        approve_interests: response.data.approveInterests || 0,
        approved_new_requests: response.data.approvedNewRequests || 0,
        new_matching_wishlist: response.data.newMatchingWishlist || 0
      });
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  // Fetch notifications when the component mounts
  useEffect(() => {
    fetchNotifications();
  }, [location.pathname, userId]);  // Re-fetch notifications when userId changes

  useEffect(() => {
    // Update the active link state whenever the location changes
    setActiveLink(location.pathname);
  }, [location]);

  //  const isActive = (path) => location.pathname === path;
  const isActive = (path) => location.pathname.startsWith(path);


  return (
    <nav className="main-nav">
      <ul>
        <li className={isActive('/myactivity') ? 'active-link' : ''}>
          <Link to="/myactivity">
            <div style={{ position: 'relative' }}>
              <FontAwesomeIcon icon={faHome} />
              {totalNotifications > 0 && (
                <span className="notification-badge">{totalNotifications}</span>
              )}
            </div>
          </Link>
        </li>


        <li className={isActive('/items') ? 'active-link' : ''}>
          <Link to="/items">Items</Link>
        </li>

        <li className={isActive('/wishes') ? 'active-link' : ''}>
          <Link to="/wishes">Wishlist</Link>
        </li>
      </ul>
    </nav>
  );
}

function App() {
  const { isLoggedIn, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = React.useRef(null);
  const buttonRef = useRef(null);
  const { userId } = useUser();
  
  
  const isAdmin = adminUserIds.includes(userId);
  console.log("Current user ID:", userId, "Is Admin:", isAdmin);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <UserProvider>
      <Router>
        <div className="App">
          {isLoggedIn && (
            <div className="header">
              <button className="hamburger" ref={buttonRef} onClick={toggleMenu}>☰</button>
              <Navigation />
              <nav className={`menu ${isOpen ? 'open' : ''}`} ref={menuRef}>
                <ul>
                  {/*<li><Link to="/myactivity" onClick={toggleMenu}>Home</Link></li>*/}
                  <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
                  <li><Link to="/FAQ" onClick={toggleMenu}>FAQ</Link></li>
                  <li><Link to="/settings" onClick={toggleMenu}>Settings</Link></li>
                  {isAdmin && (
                    <li><Link to="/admin" onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faLineChart} /> Admin
                    </Link></li>
                  )}

                  <li><button onClick={() => {
                    logout();
                    setIsOpen(false);
                  }}>Logout</button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          <div className="App-content"> {/* Main content starts here */}

            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/items" element={<ProtectedRoute><Items /></ProtectedRoute>} />
              <Route path="/wishes" element={<ProtectedRoute><Wishes /></ProtectedRoute>} />
              <Route path="/about" element={<ProtectedRoute><About /></ProtectedRoute>} />
              <Route path="/faq" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />

              <Route path="/myactivity" element={<ProtectedRoute><MyActivity /></ProtectedRoute>} />
              <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path="/admin" element={<AdminRoute><Admin /></AdminRoute>} />

            </Routes>
          </div>
        </div>

      </Router>
    </UserProvider>

  );
}

export default App;
//export default withUser(App);  // Wrap App with the HOC before exporting

