import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';
import Switch from 'react-switch';
import { useSpring, animated } from 'react-spring';

import './Settings.css';  // This should match MyActivity.css styles for consistency

function Settings() {
  const { userId } = useUser();
  const [groups, setGroups] = useState([]);
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const groupsRes = await axios.get(`/api/groups/${userId}`);
        const connectionsRes = await axios.get(`/api/connections/${userId}`);
        setGroups(groupsRes.data.sort((a, b) => b.is_subscribed - a.is_subscribed));
        setConnections(connectionsRes.data.sort((a, b) => b.is_connected - a.is_connected));
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [userId]);

  const handleGroupAction = async (groupId, action) => {
    await axios.post('/api/groups/manage', { userId, groupId, action });
    setGroups(prevGroups =>
      prevGroups.map(group =>
        group.id === groupId ? { ...group, is_subscribed: action === 'subscribe' } : group
      ).sort((a, b) => b.is_subscribed - a.is_subscribed)
    );
  };

  const handleConnectionAction = async (connectUserId, action) => {
    await axios.post('/api/connections/manage', { userId, connectUserId, action });
    setConnections(prevConnections =>
      prevConnections.map(conn =>
        conn.id === connectUserId ? { ...conn, is_connected: action === 'add' } : conn
      ).sort((a, b) => b.is_connected - a.is_connected)
    );
  };

  const toggleSection = (sectionName) => {
    setActiveSection(activeSection === sectionName ? null : sectionName);
  };

  const slideInStyles = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: activeSection ? 'auto' : 0,
      opacity: activeSection ? 1 : 0
    }
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const subscribedGroups = groups.filter(group => group.is_subscribed).length;
  const connectedIndividuals = connections.filter(conn => conn.is_connected).length;

  return (
    <div className="settings-container">
      <h5>Network & Subscriptions</h5>
      <div className="dashboard-overview mb-3 p-2 bg-light border rounded d-flex flex-wrap justify-content-between">
        {!activeSection || activeSection === 'groups' ? (
          <div className={`card text-center p-2 flex-fill m-1 clickable ${activeSection === 'groups' ? 'selected' : ''}`} onClick={() => toggleSection('groups')}>
            <h6 className="mb-1"><FontAwesomeIcon icon={faUserFriends} /> Groups</h6>
            <div className="lead">{subscribedGroups} Subscribed</div>
            <small>{groups.length} Total</small>
          </div>
        ) : null}
        {!activeSection || activeSection === 'connections' ? (
          <div className={`card text-center p-2 flex-fill m-1 clickable ${activeSection === 'connections' ? 'selected' : ''}`} onClick={() => toggleSection('connections')}>
            <h6 className="mb-1"><FontAwesomeIcon icon={faExchangeAlt} /> Connections</h6>
            <div className="lead">{connectedIndividuals} Connected</div>
            <small>{connections.length} Total</small>
          </div>
        ) : null}
      </div>

      {activeSection === 'groups' && <animated.div style={slideInStyles}>
        {groups.map(group => (
          <div key={group.id} className="card">
            <p>{group.group_name}</p>
            <p>{group.description}</p>
            <button onClick={(e) => {
              e.stopPropagation();
              handleGroupAction(group.id, group.is_subscribed ? 'unsubscribe' : 'subscribe');
            }} className={group.is_subscribed ? 'unsubscribe-button' : 'subscribe-button'}>
              {group.is_subscribed ? 'Unsubscribe' : 'Subscribe'}
            </button>
          </div>
        ))}
      </animated.div>}

      {activeSection === 'connections' && <animated.div style={slideInStyles}>
        {connections.map(conn => (
          <div key={conn.id} className={`card ${conn.is_connected ? 'connected' : 'disconnected'}`}>
            <p>{conn.name}</p>
            <Switch
              onChange={() => handleConnectionAction(conn.id, conn.is_connected ? 'remove' : 'add')}
              checked={conn.is_connected}
              onColor="#4CAF50"
              offColor="#ccc"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={40}
            />
          </div>
        ))}
      </animated.div>}
    </div>
  );
}

export default Settings;
