import React, { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from './constants';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // State to handle initial loading

    // Function to validate session
    const validateSession = async () => {
        console.log("Validating session...");
        try {
            const response = await fetch(`${API_URL}/validate-session`, {
                method: 'GET',
                credentials: 'include' // Necessary to send cookies with the request
            });
            const data = await response.json();
            if (response.ok) {
                setLoggedIn(true);
                console.log("User is logged in.");
            } else {
                // Log the message only if the error is unexpected
                if (response.status !== 401) {
                    console.error('Session validation failed:', data.message);
                }
                setLoggedIn(false);
                console.log("User is not logged in.");
            }
        } catch (error) {
            console.error('Error validating session:', error);
            setLoggedIn(false);
        } finally {
            setIsLoading(false); // Set loading to false regardless of the outcome
            console.log("Session validation completed.");
        }
    };
    

    // Effect to check session on startup
    useEffect(() => {
        validateSession();
    }, []);

    const login = (userId) => {
        console.log("User logging in...");
        setLoggedIn(true);
        const event = new Event('userLoggedIn');
        window.dispatchEvent(event);
        console.log("Login process completed with userId:", userId);
    };

    const logout = () => {
        fetch(`${API_URL}/logout`, { method: 'POST', credentials: 'include' })
        .then(() => {
            console.log("User logging out...");
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            setLoggedIn(false);
            const event = new Event('userLoggedOut');
            window.dispatchEvent(event);
            window.location.reload(); // Optionally reload or navigate to login
        })
        .catch(error => console.error('Logout failed', error));
    };

    
    if (isLoading) {
        console.log("App is loading, waiting for session validation...");
        return <div>Loading...</div>; // Display loading indicator while validating session
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
