import React from 'react';

function ItemCard({ item, onItemSelect }) {
  const imageUrl = `/images/${item.thumbnail_url}`;

  return (
    <div className="item-card" onClick={() => onItemSelect(item)}>
      <div className="image-container">
        <img src={imageUrl} alt={item.name} />
      </div>
      <div className="card-body">
        <p>{item.name}</p>
      </div>
    </div>
  );
}

export default ItemCard;
